import {useCallback} from 'react'
import './App.css';
import Button from 'react-bootstrap/Button';
import purifier from '../../assets/purifier.png'
import { scroller, Element as ScrollElement } from 'react-scroll'
import contactbg from '../../assets/contactusbg.jpg'
import headerbg from '../../assets/headerbg.jpg'


function App() {
  const scrollToContactUs = useCallback(()=>
    scroller.scrollTo("contact-us-scroll",{
        duration: 500,
        delay: 100,
        smooth: true,
  }))
  return (
    <div className="App">
      <section id="header" style={{ backgroundImage:`url(${headerbg})`}}>
        <div>
          <h1 id="header-text">
            The Water Place
          </h1>
          <h2 id="subheader-text">
            Your key to a healthy lifestyle
          </h2>
          <h3>Reverse Osmosis Purification Systems</h3>
          <Button id="contact-btn" type="button" size='lg' variant='outline-light' onClick={scrollToContactUs}>Contact Us</Button>
        </div>
      </section>

      <section id="product">
        <div className="content-card">
        <div className="left-card">
          <img src={purifier}></img>
        </div>
        <div className="right-card">
          <h1>Reverse Osmosis Purifier</h1>
          <h2>Six Stages Of Filtration</h2>
          <p>The first three stages are to remove larger particles and chemicals such as rust, dust, insecticides and chlorine. The Reverse Osmosis Membrane then removes any remaining harmful chemicals, heavy metals, and microorganisms. Finally, the taste is improved and minerals are injected back into the water.</p>
          
          <h2>Tank and Tap</h2>
          <p>A 10-liter tank is included to store the purified water. The product also comes with a high-quality tap that can be installed adjacent to your existing taps.</p>
          <div className="price">
            <h2>R1699.00</h2>
            <Button id="order-btn" type="button" size='lg' variant='light' onClick={scrollToContactUs}>Order Now</Button>
          </div>
        </div>

        </div>
      </section>
      <ScrollElement name="contact-us-scroll"></ScrollElement>

      <div id="contact-bg-div">
          <img id="contact-bg-img" src={contactbg}></img>
      </div>
      <section id="contact-us">
        <div id="contact-info">
          <h2>Contact Us</h2>
          <h3>Phone: </h3>
          <a href="tel:076 315 6789">076 315 6789</a>
          <h3>Sales: </h3>
          <a href="mailto:sales@thewaterplace.co.za">sales@thewaterplace.co.za </a>
          <h3>Support: </h3>
          <a href="mailto:support@thewaterplace.co.za">support@thewaterplace.co.za </a>
        </div>

      </section>
      <section id="footer">
        <p>
          Copyright © 2020 TheWaterPlace. <br/> All rights reserved.
        </p>
      </section>
    </div>
  );
}

export default App;
